import { ShippingFeesModule } from "./features/cost-sheet/shipping-fees/shipping-fees.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from "@danielmoncada/angular-datetime-picker";
import { AgGridModule } from "ag-grid-angular";
import { GalleryModule } from "ng-gallery";
import { LightboxModule } from "ng-gallery/lightbox";
import { AppComponent } from "./app.component";
import { HomeLayoutComponent } from "./core/components/layouts/home-layout/home-layout.component";
import { LoginLayoutComponent } from "./core/components/layouts/login-layout/login-layout.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { AuthInterceptorService } from "./core/services/auth-interceptor.service";
import { LoaderInterceptorService } from "./core/services/loader-interceptor.service";
import { AddNewChannelFeeComponent } from "./features/cost-sheet/channel-fees/add-new-channel-fee/add-new-channel-fee.component";
import { CostSheetComponent } from "./features/cost-sheet/cost-sheet.component";
import { DownloadConfirmComponent } from "./features/cost-sheet/download-confirm/download-confirm.component";
import { AddNewShippingFeeComponent } from "./features/cost-sheet/shipping-fees/add-new-shipping-fee/add-new-shipping-fee.component";
import { LandedCostSheetComponent } from "./features/landed-cost-sheet/landed-cost-sheet.component";
import { InventoryImagesComponent } from "./features/pluggable/inventory-images/inventory-images.component";
import { ItemInstructionsComponent } from "./features/pluggable/item-instructions/item-instructions.component";
import { ItemPackagingComponent } from "./features/pluggable/item-packaging/item-packaging.component";
import { QcPhotosComponent } from "./features/pluggable/qc-photos/qc-photos.component";
import { QcPhotosModule } from "./features/pluggable/qc-photos/qc-photos.module";
import { SpecSheetImagesComponent } from "./features/pluggable/spec-sheet/spec-sheet-images/spec-sheet-images.component";
import { SpecSheetComponent } from "./features/pluggable/spec-sheet/spec-sheet.component";
import { DropdownItemsRenderer } from "./features/po-creation-tab/cell-renderers/po-dropdown.component";
import { RemoveBtnRenderer } from "./features/po-creation-tab/cell-renderers/po-remove-btn-renderer.component";
import { POPropsDialogComponent } from "./features/po-creation-tab/po-view/po-props-dialog/po-props-dialog.component";
import { CalculateDatesComponent } from "./features/po-props/calculate-dates/calculate-dates.component";
import { DatePickerCalendarComponent } from "./features/po-props/date-picker-calendar/date-picker-calendar.component";
import { DateTimePickerComponent } from "./features/po-props/date-time-picker/date-time-picker.component";
import { DatePickerComponent } from "./features/po-props/date-picker/date-picker.component";
import { PoInfoComponent } from "./features/po-props/po-info/po-info.component";
import { PoPropsComponent } from "./features/po-props/po-props.component";
import { POLComponent } from "./features/po-props/pol/pol.component";
import { PortsComponent } from "./features/po-props/ports/ports.component";
import { PurchaseDatesComponent } from "./features/po-props/purchase-dates/purchase-dates.component";
import { PurchaseDocsModule } from "./features/po-props/purchase-docs/purchase-docs.module";
import { QcPhotosDialogComponent } from "./features/po-props/qc-photos-dialog/qc-photos-dialog.component";
import { AddEditContainerComponent } from "./features/procurement-new/add-edit-container/add-edit-container.component";
import { AddToContainerComponent } from "./features/procurement-new/add-to-container/add-to-container.component";
import { EditProcurementItemPropsComponent } from "./features/procurement-new/edit-procurement-item-props/edit-procurement-item-props.component";
import { ForecastOverrideComponent } from "./features/procurement-new/forecast-override/forecast-override.component";
import { GeneratePoComponent } from "./features/procurement-new/generate-po/generate-po.component";
import { IntakeDialogComponent } from "./features/procurement-new/intake-dialog/intake-dialog.component";
import { LastWeeksSalesComponent } from "./features/procurement-new/last-weeks-sales/last-weeks-sales.component";
import { ProcurementAddPurchaseComponent } from "./features/procurement-new/procurement-add-purchase/procurement-add-purchase.component";
import { ProcurementNewComponent } from "./features/procurement-new/procurement-new.component";
import { PurchaseOrderCreateDialogComponent } from "./features/procurement-new/purchase-order-create-dialog/purchase-order-create-dialog.component";
import { PoConfirmAddComponent } from "./features/procurement-new/time-period-forecast/po-confirm-add/po-confirm-add.component";
import { TimePeriodForecastComponent } from "./features/procurement-new/time-period-forecast/time-period-forecast.component";
import { AddColumnDialogComponent } from "./features/product-screen/create-view-dialog/add-column-dialog/add-column-dialog.component";
import { CreateViewDialogComponent } from "./features/product-screen/create-view-dialog/create-view-dialog.component";
import { InstructionsDialogComponent } from "./features/product-screen/instructions-dialog/instructions-dialog.component";
import { InventoryImagesDialogComponent } from "./features/product-screen/inventory-images-dialog/inventory-images-dialog.component";
import { SpecSheetDialogComponent } from "./features/product-screen/spec-sheet-dialog/spec-sheet-dialog.component";
import { DateReportComponent } from "./features/reports/date-report/date-report.component";
import { MaterialModule } from "./material/material.module";
import { CheckboxRenderer } from "./shared/components/checkbox-renderer/checkbox-renderer.component";
import { DisabledCheckboxRenderer } from "./shared/components/checkbox-renderer/disabled-checkbox-renderer.component";
import { ConfirmDialogComponent } from "./shared/components/confirm-dialog/confirm-dialog.component";
import { GridTooltipComponent } from "./shared/components/grid-tooltip/grid-tooltip.component";
import { LoaderComponent } from "./shared/components/loader/loader.component";
import { ChannelFeesModule } from "./features/cost-sheet/channel-fees/channel-fees.module";
import { POWrapperComponent } from "./features/po-creation-tab/po-wrapper/po-wrapper.component";
import { ProductScreenComponent } from "./features/product-screen/product-screen.component";
import { CostingSheetComponent } from "./features/costing-sheet/costing-sheet.component";
import { EmailSchedulerComponent } from "./features/scheduler/email-scheduler/email-scheduler.component";
import { AllPoDocsComponent } from "./features/all-po-docs/all-po-docs.component";
import { ReportsComponent } from "./features/reports/reports.component";
import { AddNewItemComponent } from "./features/product-screen/add-new-item/add-new-item.component";
import { AllPriceRulesComponent } from "./features/product-screen/all-price-rules/all-price-rules.component";
import { FullSizeImageTooltipComponent } from "./features/product-screen/full-size-image-tooltip/full-size-image-tooltip.component";
import { ImageRendererComponent } from "./features/product-screen/image-renderer/image-renderer.component";
import { PriceChangeRulesComponent } from "./features/product-screen/price-change-rules/price-change-rules.component";
import { PriceChangerComponent } from "./features/product-screen/price-changer/price-changer.component";
import { POViewComponent } from "./features/po-creation-tab/po-view/po-view.component";
import { POCostComponent } from "./features/po-creation-tab/po-cost/po-cost.component";
import { POLandedCostComponent } from "./features/po-creation-tab/po-landed-cost/po-landed-cost.component";
import { POListComponent } from "./features/po-creation-tab/po-list/po-list.component";
import { POCreationComponent } from "./features/po-creation-tab/po-creation/po-creation.component";
import {
  MatDialogModule,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";

// reports
import { ContainerPalletReportComponent } from "./features/reports/container-pallet-report/container-pallet-report.component";
import { WayfairSalesWeeklyComponent } from "./features/reports/wayfair-sales-weekly/wayfair-sales-weekly.component";
import { AmazonSalesDatesSetComponent } from "./features/reports/amazon-sales-dates-set/amazon-sales-dates-set.component";
import { AmazonSalesEuropeComponent } from "./features/reports/amazon-sales-europe/amazon-sales-europe.component";
import { ArrowComponent } from "./features/reports/arrow/arrow.component";
import { BarcodeReportComponent } from "./features/reports/barcode-report/barcode-report.component";
import { BedSalesCategoryComponent } from "./features/reports/bed-sales-category/bed-sales-category.component";
import { BedWeeklySalesComponent } from "./features/reports/bed-weekly-sales/bed-weekly-sales.component";
import { CarrierReportComponent } from "./features/reports/carrier-report/carrier-report.component";
import { CashFlowReportComponent } from "./features/reports/cash-flow-report/cash-flow-report.component";
import { CashFlowWithFurtherInfoReportComponent } from "./features/reports/cash-flow-with-further-info-report/cash-flow-with-further-info-report.component";
import { ContainerReportComponent } from "./features/reports/container-report/container-report.component";
import { DeliveredPurchaseOrdersComponent } from "./features/reports/delivered-purchase-orders/delivered-purchase-orders.component";
import { EbaySalesDatesSetComponent } from "./features/reports/ebay-sales-dates-set/ebay-sales-dates-set.component";
import { FavouriteItemSalesComponent } from "./features/reports/favourite-item-sales/favourite-item-sales.component";
import { FbaEuropeStockComponent } from "./features/reports/fba-europe-stock/fba-europe-stock.component";
import { FbaSalesReportComponent } from "./features/reports/fba-sales-report/fba-sales-report.component";
import { FBAStockReportComponent } from "./features/reports/fba-stock-report/fba-stock-report.component";
import { FbaUkStockComponent } from "./features/reports/fba-uk-stock/fba-uk-stock.component";
import { MonthlySalesReportComponent } from "./features/reports/monthly-sales-report/monthly-sales-report.component";
import { MonthlyValueOfImportsComponent } from "./features/reports/monthly-value-import/monthly-value-import.component";
import { OrdersFromTuffnellsComponent } from "./features/reports/orders-from-tuffnells/orders-from-tuffnells.component";
import { PalleteReportComponent } from "./features/reports/pallete-report/pallete-report.component";
import { PaymentReportComponent } from "./features/reports/payment-report/payment-report.component";
import { PhotoPerSkuComponent } from "./features/reports/photo-per-sku/photo-per-sku.component";
import { POListItemComponent } from "./features/reports/po-list-item/po-list-item.component";
import { POScheduleComponent } from "./features/reports/po-schedule/po-schedule.component";
import { PoScheduleAivanReportComponent } from "./features/reports/po-schedule-aivan-report/po-schedule-aivan-report.component";
import { SalesByChannelComponent } from "./features/reports/sales-by-channel/sales-by-channel.component";
import { SalesByChannelForSkuComponent } from "./features/reports/sales-by-channel-for-sku/sales-by-channel-for-sku.component";
import { SalesWtihPriceChangesComponent } from "./features/reports/sales-wtih-price-changes/sales-wtih-price-changes.component";
import { SellerFlexStockComponent } from "./features/reports/seller-flex-stock/seller-flex-stock.component";
import { SellerFlexStockNewComponent } from "./features/reports/seller-flex-stock-new/seller-flex-stock-new.component";
import { ShippingForwarderComponent } from "./features/reports/shipping-forwarder/shipping-forwarder.component";
import { SlowSellingLinesComponent } from "./features/reports/slow-selling-lines/slow-selling-lines.component";
import { SpatialComponent } from "./features/reports/spatial/spatial.component";
import { StockTakeLowQuantityComponent } from "./features/reports/stock-take-low-quantity/stock-take-low-quantity.component";
import { StockTakeRandomComponent } from "./features/reports/stock-take-random/stock-take-random.component";
import { StockTakeSheetComponent } from "./features/reports/stock-take-sheet/stock-take-sheet.component";
import { StockValuationComponent } from "./features/reports/stock-valuation/stock-valuation.component";
import { StockValuationWithFreightCostsComponent } from "./features/reports/stock-valuation-with-freight-costs/stock-valuation-with-freight-costs.component";
import { StockValueParentsOnlyComponent } from "./features/reports/stock-value-parents-only/stock-value-parents-only.component";
import { StockValueWithSoldFreightComponent } from "./features/reports/stock-value-with-sold-freight/stock-value-with-sold-freight.component";
import { SupplierProductItemsComponent } from "./features/reports/supplier-product-items/supplier-product-items.component";
import { SupplierProductItemsWithPhotosComponent } from "./features/reports/supplier-product-items-with-photos/supplier-product-items-with-photos.component";
import { TotalCbmAndTotalWeightByOrderComponent } from "./features/reports/total-cbm-and-total-weight-by-order/total-cbm-and-total-weight-by-order.component";
import { UkFreightCostComponent } from "./features/reports/uk-freight-cost/uk-freight-cost.component";
import { WayfairSalesDatesSetComponent } from "./features/reports/wayfair-sales-dates-set/wayfair-sales-dates-set.component";
import { WeeklySalesComponent } from "./features/reports/weekly-sales/weekly-sales.component";
import { WeeklySalesNewComponent } from "./features/reports/weekly-sales-new/weekly-sales-new.component";
import { WowCostingComponent } from "./features/reports/wow-costing/wow-costing.component";
import { WowSalesComponent } from "./features/reports/wow-sales/wow-sales.component";
import { WowStockReportComponent } from "./features/reports/wow-stock-report/wow-stock-report.component";
import { TotalResendsRefundsReturnsNumbers } from "./features/reports/total-resends-refunds-returns-numbers/total-resends-refunds-returns-numbers.component";
import { UsersComponent } from "./core/components/users/users.component";
import { SelectRecepientComponent } from "./features/po-creation-tab/po-view/select-recepient/select-recepient.component";
import { LandedCostByStockitemComponent } from "./features/reports/landed-cost-by-stockitem/landed-cost-by-stockitem.component";
import { ReturnReasonForEachSkuComponent } from "./features/reports/return-reason-for-each-sku/return-reason-for-each-sku.component";
import { SellerFlexLowQtyComponent } from "./features/reports/seller-flex-low-qty/seller-flex-low-qty.component";
import { NewItemsWeeklySalesComponent } from "./features/reports/new-items-weekly-sales/new-items-weekly-sales.component";
import { AmazonShippingReportComponent } from "./features/reports/amazon-shipping-report/amazon-shipping-report.component";
import { WowcherAllocationTransferComponent } from "./features/reports/wowcher-allocation-transfer/wowcher-allocation-transfer.component";
import { SellerFlexAllocationComponent } from "./features/reports/seller-flex-allocation/seller-flex-allocation.component";
import { EntryDocumentsReceivedComponent } from "./features/reports/entry-documents-received/entry-documents-received.component";
import { NewUserComponent } from "./core/components/users/new-user/new-user.component";
import { UpdatePasswordComponent } from "./core/components/users/update-password/update-password.component";
import { GeneralSettingsComponent } from "./core/components/users/general-settings/general-settings.component";
import { SelectEmailRecepientsDialogComponent } from "./shared/components/select-email-recepients-dialog/select-email-recepients-dialog.component";
import { StockValueWorkingComponent } from "./features/reports/stock-value-working/stock-value-working.component";
import { StockValueSummaryComponent } from "./features/reports/stock-value-summary/stock-value-summary.component";
import { SuppliersStateConfigurationComponent } from "./features/product-screen/suppliers-state-configuration/suppliers-state-configuration.component";
import { GoToStreamComponent } from "./features/reports/go-to-stream/go-to-stream.component";
import { ReportSchedulerComponent } from "./features/scheduler/report-scheduler/report-scheduler.component";
import { EmailRecepientsComponent } from "./features/scheduler/email-recepients/email-recepients.component";
import { ScheduleBuilderComponent } from "./features/scheduler/schedule-builder/schedule-builder.component";
import { CronEditorModule } from "ngx-cron-editor";
import { OrdersComponent } from "./features/stream/components/orders/orders.component";
import { RunsOverviewComponent } from "./features/stream/components/runs-overview/runs-overview.component";
import { SendEmailsDialogComponent } from "./features/stream/components/send-emails-dialog/send-emails-dialog.component";
import { ChannelLinkingComponent } from "./features/reports/channel-linking/channel-linking.component";
import { StockItemSuppliersComponent } from "./features/product-screen/stock-item-suppliers/stock-item-suppliers.component";
import { ChannelSalesInSalesOrderComponent } from "./features/reports/channel-sales-in-sales-order/channel-sales-in-sales-order.component";
import { GoodsReadyReportComponent } from "./features/reports/goods-ready-report/goods-ready-report.component";
import { ImageTooltipDirective } from './core/directives/image-tooltip.directive';

@NgModule({
  declarations: [
    AppComponent,
    UpdatePasswordComponent,
    NewUserComponent,
    IntakeDialogComponent,
    SelectEmailRecepientsDialogComponent,
    LastWeeksSalesComponent,
    PurchaseOrderCreateDialogComponent,
    AddEditContainerComponent,
    AddToContainerComponent,
    GeneratePoComponent,
    ForecastOverrideComponent,
    ConfirmDialogComponent,
    EditProcurementItemPropsComponent,
    TimePeriodForecastComponent,
    ProcurementNewComponent,
    PoConfirmAddComponent,
    CostSheetComponent,
    LandedCostSheetComponent,
    LoaderComponent,
    GridTooltipComponent,
    PoPropsComponent,
    PoInfoComponent,
    CheckboxRenderer,
    DisabledCheckboxRenderer,
    DownloadConfirmComponent,
    DatePickerComponent,
    DatePickerCalendarComponent,
    DateTimePickerComponent,
    PortsComponent,
    POLComponent,
    RemoveBtnRenderer,
    DropdownItemsRenderer,
    PortsComponent,
    POLComponent,
    POPropsDialogComponent,
    InventoryImagesComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    SpecSheetComponent,
    SpecSheetImagesComponent,
    ItemInstructionsComponent,
    ItemPackagingComponent,
    CalculateDatesComponent,
    PurchaseDatesComponent,
    DateReportComponent,
    ProcurementAddPurchaseComponent,
    QcPhotosDialogComponent,
    CreateViewDialogComponent,
    AddColumnDialogComponent,
    InventoryImagesDialogComponent,
    SpecSheetDialogComponent,
    InstructionsDialogComponent,
    AddNewChannelFeeComponent,
    AddNewShippingFeeComponent,
    POWrapperComponent,
    ProductScreenComponent,
    CostingSheetComponent,
    EmailSchedulerComponent,
    AllPoDocsComponent,
    ReportsComponent,
    ImageRendererComponent,
    FullSizeImageTooltipComponent,
    AddNewItemComponent,
    PriceChangerComponent,
    PriceChangeRulesComponent,
    SuppliersStateConfigurationComponent,
    AllPriceRulesComponent,
    POCreationComponent,
    POViewComponent,
    POCostComponent,
    POLandedCostComponent,
    POListComponent,
    ContainerPalletReportComponent,
    WayfairSalesWeeklyComponent,
    StockValuationComponent,
    AmazonSalesDatesSetComponent,
    ArrowComponent,
    BarcodeReportComponent,
    BedSalesCategoryComponent,
    BedWeeklySalesComponent,
    CarrierReportComponent,
    CashFlowReportComponent,
    CashFlowWithFurtherInfoReportComponent,
    ContainerReportComponent,
    DeliveredPurchaseOrdersComponent,
    EbaySalesDatesSetComponent,
    FavouriteItemSalesComponent,
    FbaEuropeStockComponent,
    FbaSalesReportComponent,
    FBAStockReportComponent,
    FbaUkStockComponent,
    FbaUkStockComponent,
    MonthlySalesReportComponent,
    MonthlyValueOfImportsComponent,
    OrdersFromTuffnellsComponent,
    PalleteReportComponent,
    PaymentReportComponent,
    PhotoPerSkuComponent,
    POListItemComponent,
    POScheduleComponent,
    PoScheduleAivanReportComponent,
    SalesByChannelComponent,
    SalesByChannelForSkuComponent,
    SalesWtihPriceChangesComponent,
    SellerFlexStockComponent,
    SellerFlexStockNewComponent,
    ShippingForwarderComponent,
    SlowSellingLinesComponent,
    SpatialComponent,
    StockTakeLowQuantityComponent,
    StockTakeRandomComponent,
    StockTakeSheetComponent,
    StockValuationComponent,
    StockValuationWithFreightCostsComponent,
    StockValueParentsOnlyComponent,
    StockValueWithSoldFreightComponent,
    SupplierProductItemsComponent,
    SupplierProductItemsWithPhotosComponent,
    TotalCbmAndTotalWeightByOrderComponent,
    UkFreightCostComponent,
    WayfairSalesDatesSetComponent,
    WeeklySalesComponent,
    WeeklySalesNewComponent,
    WowCostingComponent,
    WowSalesComponent,
    WowStockReportComponent,
    LandedCostByStockitemComponent,
    ReturnReasonForEachSkuComponent,
    SellerFlexLowQtyComponent,
    NewItemsWeeklySalesComponent,
    TotalResendsRefundsReturnsNumbers,
    AmazonShippingReportComponent,
    AmazonSalesEuropeComponent,
    WowcherAllocationTransferComponent,
    SellerFlexAllocationComponent,
    EntryDocumentsReceivedComponent,
    UsersComponent,
    SelectRecepientComponent,
    GeneralSettingsComponent,
    StockValueWorkingComponent,
    StockValueSummaryComponent,
    GoToStreamComponent,
    EmailSchedulerComponent,
    EmailRecepientsComponent,
    ReportSchedulerComponent,
    ScheduleBuilderComponent,
    OrdersComponent,
    RunsOverviewComponent,
    SendEmailsDialogComponent,
    ChannelLinkingComponent,
    StockItemSuppliersComponent,
    ChannelSalesInSalesOrderComponent,
    GoodsReadyReportComponent,
    ImageTooltipDirective,
  ],

  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    GalleryModule,
    LightboxModule,
    ShippingFeesModule,
    ChannelFeesModule,
    AgGridModule.withComponents([
      CheckboxRenderer,
      DatePickerComponent,
      DatePickerCalendarComponent,

      DateTimePickerComponent,
      RemoveBtnRenderer,
      DropdownItemsRenderer,
    ]),

    RouterModule.forRoot([
      { path: "", redirectTo: "login", pathMatch: "full" },
      { path: "login", component: LoginLayoutComponent },
      {
        path: "home",
        component: HomeLayoutComponent,
        children: [
          {
            path: "reports",
            component: ReportsComponent,
            data: { componentName: "All Reports" },
            children: [
              {
                path: "container-pallet-report",
                component: ContainerPalletReportComponent,
                data: { componentName: "Container Pallet Report" },
              },
              {
                path: "wayfair-sales-weekly",
                component: WayfairSalesWeeklyComponent,
                data: { componentName: "Wayfair Sales Weekly" },
              },
              {
                path: "stock-value-simple",
                component: StockValuationComponent,
                data: { componentName: "Stock Value" },
              },
              {
                path: "stock-valuation-with-freight-costs",
                component: StockValuationWithFreightCostsComponent,
                data: { componentName: "Stock Value with Freight" },
              },
              {
                path: "weekly-sales-all",
                component: WeeklySalesComponent,
                data: { componentName: "Weekly Sales" },
              },
              { path: "weekly-sales-new", component: WeeklySalesNewComponent },
              {
                path: "bed-weekly-sales",
                component: BedWeeklySalesComponent,
                data: { componentName: "Bed Weekly Sales" },
              },
              {
                path: "bed-sales-category",
                component: BedSalesCategoryComponent,
                data: { componentName: "Bed Weekly Sales By Category" },
              },
              {
                path: "sales-by-channel",
                component: SalesByChannelComponent,
                data: { componentName: "Sales By Channel" },
              },
              {
                path: "po-list-item",
                component: POListItemComponent,
                data: { componentName: "PO List Item" },
              },
              {
                path: "favourite-item-sales",
                component: FavouriteItemSalesComponent,
                data: { componentName: "Favourite" },
              },
              {
                path: "supplier-product-items",
                component: SupplierProductItemsComponent,
                data: { componentName: "Supplier Product Items" },
              },
              {
                path: "supplier-product-items-with-photos",
                component: SupplierProductItemsWithPhotosComponent,
              },
              {
                path: "po-schedule",
                component: POScheduleComponent,
                data: { componentName: "PO Schedule" },
              },
              {
                path: "pallete-report",
                component: PalleteReportComponent,
                data: { componentName: "Pallete Report" },
              },
              {
                path: "monthly-value-import",
                component: MonthlyValueOfImportsComponent,
                data: { componentName: "Monthly Value of Imports" },
              },
              {
                path: "fba-stock-report",
                component: FBAStockReportComponent,
                data: { componentName: "FBA Stock" },
              },
              {
                path: "slow-selling-lines",
                component: SlowSellingLinesComponent,
                data: { componentName: "Slow Selling Lines" },
              },
              {
                path: "container-report",
                component: ContainerReportComponent,
                data: { componentName: "Container Report" },
              },
              {
                path: "sales-with-price-changes",
                component: SalesWtihPriceChangesComponent,
                data: { componentName: "Sales wit Price Changes" },
              },
              {
                path: "cash-flow-report",
                component: CashFlowReportComponent,
                data: { componentName: "Cash Flow" },
              },
              {
                path: "cash-flow-with-further-info",
                component: CashFlowWithFurtherInfoReportComponent,
              },
              {
                path: "po-schedule-aivan-report",
                component: PoScheduleAivanReportComponent,
                data: { componentName: "PO Schedule Alvan" },
              },
              {
                path: "barcode-report",
                component: BarcodeReportComponent,
                data: { componentName: "Barcode Report" },
              },
              {
                path: "wow-stock-report",
                component: WowStockReportComponent,
                data: { componentName: "Wow Stock" },
              },
              {
                path: "fba-sales-report",
                component: FbaSalesReportComponent,
                data: { componentName: "FBA Sales" },
              },
              {
                path: "monthly-sales-report",
                component: MonthlySalesReportComponent,
                data: { componentName: "Monthly Sales" },
              },
              {
                path: "payment-report",
                component: PaymentReportComponent,
                data: { componentName: "Payment Report" },
              },
              {
                path: "wayfair-sales-dates-set",
                component: WayfairSalesDatesSetComponent,
                data: { componentName: "Wayfair Sales" },
              },
              {
                path: "ebay-sales-dates-set",
                component: EbaySalesDatesSetComponent,
                data: { componentName: "Ebay Sales Dates Set" },
              },
              {
                path: "photo-per-sku",
                component: PhotoPerSkuComponent,
                data: { componentName: "Photo Per Sku" },
              },
              {
                path: "delivered-purchase-orders",
                component: DeliveredPurchaseOrdersComponent,
                data: { componentName: "Delivered Purchase Orders" },
              },
              {
                path: "wow-costing",
                component: WowCostingComponent,
                data: { componentName: "Wow Costing" },
              },
              {
                path: "shipping-forwarder",
                component: ShippingForwarderComponent,
                data: { componentName: "Shipping Forwarder" },
              },
              {
                path: "wow-sales",
                component: WowSalesComponent,
                data: { componentName: "WOW Sales" },
              },
              {
                path: "fba-uk-stock",
                component: FbaUkStockComponent,
                data: { componentName: "FBA UK Stock" },
              },
              {
                path: "fba-europe-stock",
                component: FbaEuropeStockComponent,
                data: { componentName: "FBA Europe Stock" },
              },
              {
                path: "stock-value-parents-only",
                component: StockValueParentsOnlyComponent,
                data: { componentName: "Stock Value Parents Only" },
              },
              {
                path: "orders-from-tuffnells",
                component: OrdersFromTuffnellsComponent,
                data: { componentName: "Orders From Tuffnells" },
              },
              {
                path: "spatial-report",
                component: SpatialComponent,
                data: { componentName: "Spatial Report" },
              },
              {
                path: "total-cbm-and-total-weight-by-order",
                component: TotalCbmAndTotalWeightByOrderComponent,
                data: { componentName: "Total Cbm And Total Weight By Order" },
              },
              {
                path: "uk-freight-cost",
                component: UkFreightCostComponent,
                data: { componentName: "UK Freight Cost" },
              },
              {
                path: "stock-value-with-sold-freight",
                component: StockValueWithSoldFreightComponent,
                data: { componentName: "Stock Value With Sold Freight" },
              },
              {
                path: "sales-by-channel-for-sku",
                component: SalesByChannelForSkuComponent,
                data: { componentName: "Sales By Channel For SKU" },
              },
              {
                path: "amazon-sales-dates-set",
                component: AmazonSalesDatesSetComponent,
                data: { componentName: "Amazon Sales Dates Set" },
              },
              {
                path: "seller-flex-stock",
                component: SellerFlexStockComponent,
                data: { componentName: "Seller Flex Stock" },
              },
              {
                path: "carrier-report",
                component: CarrierReportComponent,
                data: { componentName: "Carrier Report" },
              },
              {
                path: "stock-take-sheet-report",
                component: StockTakeSheetComponent,
                data: { componentName: "Stock Take Sheet" },
              },
              {
                path: "stock-take-random-report",
                component: StockTakeRandomComponent,
                data: { componentName: "Stock Take Random" },
              },
              {
                path: "stock-take-low-quantity-report",
                component: StockTakeLowQuantityComponent,
                data: { componentName: "Stock Take Low Quantity" },
              },
              {
                path: "arrow",
                component: ArrowComponent,
                data: { componentName: "Arrow Reports" },
              },
              {
                path: "total-resends-refunds-returns-numbers",
                component: TotalResendsRefundsReturnsNumbers,
                data: { componentName: "Total Resends Refunds Returns" },
              },
              {
                path: "seller-flex-new",
                component: SellerFlexStockNewComponent,
                data: { componentName: "Seller Flex Stock New" },
              },
              {
                path: "landedCostByStockItem",
                component: LandedCostByStockitemComponent,
                data: { componentName: "Landed Costs By SKU" },
              },
              {
                path: "returnReasonForEachSKU",
                component: ReturnReasonForEachSkuComponent,
                data: { componentName: "Return Reason Report" },
              },
              {
                path: "seller-flex-low-qty",
                component: SellerFlexLowQtyComponent,
                data: { componentName: "Seller Flex Low Qty" },
              },
              {
                path: "new-items-sales-weekly",
                component: NewItemsWeeklySalesComponent,
                data: { componentName: "New Items Weekly Sales" },
              },
              {
                path: "amazon-shipping-report",
                component: AmazonShippingReportComponent,
                data: { componentName: "Amz Shipping Report" },
              },
              {
                path: "amazonSalesEurope",
                component: AmazonSalesEuropeComponent,
                data: { componentName: "Amz Sales Europe Report" },
              },
              {
                path: "wowcher-allocation-transfer",
                component: WowcherAllocationTransferComponent,
                data: { componentName: "Wowcher Allocation/Transfer Report" },
              },
              {
                path: "seller-flex-allocation",
                component: SellerFlexAllocationComponent,
                data: { componentName: "Seller Flex Allocation Report" },
              },
              {
                path: "entryDocsReport",
                component: EntryDocumentsReceivedComponent,
                data: { componentName: "Entry Documents Received Report" },
              },
              {
                path: "stockValueWorking",
                component: StockValueWorkingComponent,
                data: { componentName: "Stock Value Working" },
              },
              {
                path: "stockValueSummary",
                component: StockValueSummaryComponent,
                data: { componentName: "Stock Value Summary" },
              },
              {
                path: "go2stream",
                component: GoToStreamComponent,
                data: { componentName: "Go2Stream Orders" },
              },
              {
                path: "channelLinking",
                component: ChannelLinkingComponent,
                data: { componentName: "Channel Linking" },
              },
              {
                path: 'channelsalesinsalesorder',
                component: ChannelSalesInSalesOrderComponent,
                data: { componentName: 'Channel Sales in sales order'}
              },
              {
                path: 'goodsReadyReport',
                component: GoodsReadyReportComponent,
                data: { componentName: 'Goods Ready PO'}
              }
            ],
          },

          {
            path: "product-screen",
            component: ProductScreenComponent,
            data: { componentName: "Product Details" },
          },
          {
            path: "po-props",
            component: PoPropsComponent,
            data: { componentName: "PO Screen" },
          },
          {
            path: "landed-cost-sheet",
            component: LandedCostSheetComponent,
            data: { componentName: "Landed Cost Sheet" },
          },
          {
            path: "costing-sheet",
            component: CostingSheetComponent,
            data: { componentName: "Costing Details" },
          },
          {
            path: "procurement-test",
            component: ProcurementNewComponent,
            data: { componentName: "Procurement Sheet" },
          },
          {
            path: "po-purchase-list",
            component: POListComponent,
            data: { componentName: "PO List" },
          },
          {
            path: "po-cost",
            component: POCostComponent,
            data: { componentName: "PO Cost" },
          },
          {
            path: "po-landed-cost",
            component: POLandedCostComponent,
            data: { componentName: "PO Landed Cost" },
          },
          {
            path: "po-view",
            component: POViewComponent,
            data: { componentName: "PO View" },
          },
          {
            path: "po-creation",
            component: POCreationComponent,
            data: { componentName: "PO Creation" },
          },
          {
            path: "email-scheduler",
            component: ReportSchedulerComponent,
            data: { componentName: "Email Scheduler" },
          },
          {
            path: "all-po-docs",
            component: AllPoDocsComponent,
            data: { componentName: "Search PO Docs" },
          },
          {
            path: "users",
            component: UsersComponent,
            data: { componentName: "Users" },
          },
          {
            path: "settings",
            component: GeneralSettingsComponent,
            data: { componentName: "Settings" },
          },
          {
            path: "streamnew",
            component: RunsOverviewComponent,
            data: { componentName: "Stream" },
          },
        ],
      },

      { path: "spec-sheet", component: SpecSheetComponent },
      { path: "inventory-images", component: InventoryImagesComponent },
      { path: "inventory-packaging", component: ItemPackagingComponent },
      { path: "inventory-instructions", component: ItemInstructionsComponent },
      { path: "qc-photos", component: QcPhotosComponent },

      { path: "**", redirectTo: "home" },
    ]),
    BrowserAnimationsModule,
    MaterialModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatTooltipModule,
    PurchaseDocsModule,
    QcPhotosModule,
    CronEditorModule,
    AgGridModule.withComponents([
      CheckboxRenderer,
      DisabledCheckboxRenderer,
      DatePickerComponent,
      DatePickerCalendarComponent,
      DateTimePickerComponent,
      RemoveBtnRenderer,
      DropdownItemsRenderer,
    ]),
    MatDialogModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    AuthGuard,
    { provide: MAT_DATE_LOCALE, useValue: "en-NL" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
