import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { isDevMode } from '@angular/core';
import { Route, Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserConfig } from 'src/app/shared/models/user-config';
import { UserService } from 'src/app/shared/services/user.service';
import { Tab } from '../../../interfaces/tab';
import { POItemsBySupplierService } from "src/app/features/po-creation-tab/po-creation/po-items.service";

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss'],
})
export class HomeLayoutComponent implements OnInit {

  authenticatedUser: User;
  destroySubj = new Subject();
  isDevMode: boolean;
  showIncresedSideBar: boolean = false;

  public tabs: Tab[] = [];
  public routes: Route[] = [];
  public selectedTabIndex: number = 0;
  public userConfig: UserConfig;

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private http: HttpClient,
    private authenticationWebService: AuthService,
    private poService: POItemsBySupplierService
  ) {

    this.isDevMode = isDevMode();

    router.events.subscribe(val => {
      if (val instanceof RoutesRecognized) {
        this.checkAndAddRouteTab(val);
      }
    });

  }

  ngOnInit(): void {
    this.authenticationWebService.authenticatedUserSubject.subscribe(
      (authUser) => {
        this.authenticatedUser = authUser;

        if (this.authenticatedUser) {
          this.userService.getUser(this.authenticatedUser.email).subscribe(r => {
            this.userService.currentUserConfig = r;
            this.userConfig = r;
            debugger;
            this.router.config.find(r => r.path === 'home')?.children?.forEach(r => {
              if (r.children && r.children.length > 0
                && !r.path.includes('reports')
              ) {
                this.checkPermissionsAndPushRoute(r);

                r.children.forEach(rc => {
                  this.checkPermissionsAndPushRoute(rc);
                });
              } else {
                this.checkPermissionsAndPushRoute(r);
              }
            });

            if (this.routes && this.routes.length && this.userConfig.defaultTabs) {
              this.userConfig.defaultTabs.forEach(t => {
                if (!this.tabs?.some(x => x.route === t)) {
                  const defaultRoute = this.routes.find(r => r.path === t);
                  setTimeout(() => {
                    this.router.navigate([defaultRoute.path], { relativeTo: this.activatedRoute });
                  }, 200);
                }
                
              });
            }
            this.refreshToken();
          });

        }
      }
    );


    setInterval(() => {
      this.refreshToken();
    }, 25 * 60 * 1000);

    // interval(5*60*1000).pipe(switchMap(() => this.refreshToken())).subscribe(()=>{});

    this.poService.closeTab$.pipe(
      tap((tab: string) => {
        this.disposeTab(this.tabs?.find(t => t.name.includes(tab))!);
      })
    ).subscribe();
  }

  private refreshToken() {
    this.http.get('api/authorization/refresh-linn-token').pipe(tap((session: any) => {
      let userAuth = JSON.parse(localStorage.getItem('authUserData'));
      userAuth._linnworksToken = session.token;
      localStorage.setItem('authUserData', JSON.stringify(userAuth));
    })).subscribe();
  }

  logOut(): void {
    this.authenticationWebService.logoutUser();
  }

  ngOnDestroy(): void {
    this.destroySubj.next(null);
    this.destroySubj.complete();
  }

  checkPermissionsAndPushRoute(route: Route): void {
    if (this.userConfig.email !== 'kabir@doodle-products.com') {
      if (this.userConfig.allowedTabs.includes(route.path)) {
        this.routes.push(route);
      }
    } else {
      this.routes.push(route);
    }
  }

  checkAndAddRouteTab(val: RoutesRecognized) {
    // get the component to activate by the route

    if (val.url === '/home') {
      return;
    }
    const route = this.routes?.find(c => val.url.includes(c.path!)) ?? this.routes?.find(c => c.path?.includes('reports'))?.children?.find(c => val.url?.includes(c.path!));

    // const comp: string | Type<any> | null | undefined = val.state.root.firstChild?.routeConfig?.children?.filter(c => val.url.includes(c.path!))[0].component;
    const comp = route?.component;
    // deactivate all tabs
    this.deactivateTabs();

    let componentIndex = val.state.root.queryParams['componentIndex'];

    if (val.url.includes('po-view') && !componentIndex) {
      componentIndex = '   ';
    }
    const tabNameSuffix = componentIndex ? ` - ${componentIndex}` : '';
    const expectedTabName = `${route?.data?.['componentName']!}${tabNameSuffix}`;

    // check if the tab to be activated is already existing
    if (this.tabs.find((tab) => tab.name === route?.data?.componentName!) == null) {
      // if not, push it into the tab array
      const newTab = {
        name: expectedTabName,
        component: comp,
        key: expectedTabName,
        active: true,
        route: val.state.root.firstChild?.routeConfig?.children?.filter(c => val.url.includes(c.path!))[0]!,
      };
      this.tabs.push(newTab);
      this.selectedTabIndex = this.tabs.indexOf(newTab);
      this.poService.currentTab = newTab.name;
    } else {
      // if the tab exists, activate it
      const tabToActivate = this.tabs.find((tab) => tab.name == expectedTabName)!;

      if (tabToActivate) {
        this.selectedTabIndex = this.tabs.indexOf(tabToActivate);
        tabToActivate.active = true;
        

    this.poService.currentTab = tabToActivate.name;
      }
    }

    this.cd.markForCheck();
  }

  deactivateTabs() {
    this.tabs.forEach(tab => (tab.active = false));
  }


  disposeTab(tab: Tab) {
    if (this.tabs.length > 1) {
      this.tabs = this.tabs.filter((item) => item.name !== tab.name);

      if (tab.active) {
        // deactivate all tabs
        this.deactivateTabs();
        if (this.tabs?.length) {
          this.selectedTabIndex = this.tabs.length - 1;
          this.tabs[this.tabs.length - 1].active = true;
          
        this.router.navigate([this.tabs[this.tabs.length - 1].route.path!], this.tabs[this.tabs.length - 1].route.data);
        }
      }
    }
  }

  activateTab() {
    this.poService.currentTab = this.tabs[this.selectedTabIndex].name;
  }

  increase() {
    setTimeout(() => {
      this.showIncresedSideBar = true;
    }, 200);
  }

  decrease() {
    setTimeout(() => {
      this.showIncresedSideBar = false;
    }, 200);
  }
}
