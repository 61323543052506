import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appImageTooltip]'
})
export class ImageTooltipDirective {
  @Input('appImageTooltip') tooltipImage!: string;
  private tooltipElement!: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter') onMouseEnter() {
    if (this.tooltipImage) {
      this.tooltipElement = this.renderer.createElement('div');
      const rect = this.el.nativeElement.getBoundingClientRect();

      // Style the tooltip
      this.renderer.setStyle(this.tooltipElement, 'position', 'absolute');
      this.renderer.setStyle(this.tooltipElement, 'top', `${rect.top + window.scrollY}px`);
      this.renderer.setStyle(this.tooltipElement, 'left', `${rect.left + window.scrollX + rect.width + 200}px`);
      this.renderer.setStyle(this.tooltipElement, 'transform', 'translateX(-50%)');
      this.renderer.setStyle(this.tooltipElement, 'background', '#fff');
      this.renderer.setStyle(this.tooltipElement, 'border', '1px solid #ccc');
      this.renderer.setStyle(this.tooltipElement, 'padding', '5px');
      this.renderer.setStyle(this.tooltipElement, 'box-shadow', '0 4px 8px rgba(0, 0, 0, 0.1)');
      this.renderer.setStyle(this.tooltipElement, 'z-index', '1000');
      this.renderer.setStyle(this.tooltipElement, 'white-space', 'nowrap');

      // Add the larger image
      const img = this.renderer.createElement('img');
      this.renderer.setStyle(img, 'width', '300px');
      this.renderer.setStyle(img, 'height', 'auto');
      this.renderer.setProperty(img, 'src', this.tooltipImage);

      // Append the image to the tooltip
      this.renderer.appendChild(this.tooltipElement, img);

      // Append the tooltip to the body
      this.renderer.appendChild(document.body, this.tooltipElement);
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
    }
  }
}
